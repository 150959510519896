<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'HarmonyLayout',
};
</script>

<style scoped>

</style>
